﻿@import "../ui-variables";

body {
    &.win-accent {
        .accent-mixin(@accents, "body");
    }
}


div,
pre,
section,
footer,
header {
    &.win-accent {
        .accent-mixin(@accents, "container");
    }



    &.win-accent-dark {
        background:
            linear-gradient(
              rgba(0, 0, 0, 0.05), 
              rgba(0, 0, 0, 0.05)
            );
    }

    &.win-accent-darker {
        background:
            linear-gradient(
              rgba(0, 0, 0, 0.1), 
              rgba(0, 0, 0, 0.1)
            );
    }

    &.win-accent-darkest {
        background:
            linear-gradient(
              rgba(0, 0, 0, 2.5), 
              rgba(0, 0, 0, 2.5)
            );
    }


    &.win-accent-light {
        background:
            linear-gradient(
              rgba(255, 255, 255, 0.05), 
              rgba(255, 255, 255, 0.05)
            );
    }

    &.win-accent-lighter {
        background:
            linear-gradient(
              rgba(255, 255, 255, 0.1), 
              rgba(255, 255, 255, 0.1)
            );
    }

    &.win-accent-lightest {
        background:
            linear-gradient(
              rgba(255, 255, 255, 2.5), 
              rgba(255, 255, 255, 2.5)
            );
    }


    &.emphasize {
        background-color: @emphasize-color;
    }
}


.win-type-header,
.win-type-subheader,
.win-type-title,
.win-type-subtitle,
.win-type-jumbo,
.win-type-base,
.win-type-body,
.win-type-lead,
.win-type-caption,
.win-h1,
.win-h2,
.win-h3,
.win-h4,
.win-h5,
.win-h6,
.win-button,
.win-dropdown,
.win-textbox,
.win-link,
.win-textarea,
[class*="win-icon-"] {

    &.win-accent {
        .accent-mixin(@accents, "text");
    }

    &.emphasize {
        font-weight: bold !important;
    }
}






.accent-mixin(@list, @type) {

    .loop(@i) when (@i > 0) {
        .action(@i);
        .loop(@i - 1);
    }

    .action(@index) {
        @key: extract(extract(@list, @index), 1);
        @value: extract(extract(@list, @index), 2);


        &-@{key} when (@type = "body") {
            // this should allow us to express the accent color once i.e ".win-accent-amber"
            // and then use ".win-accent" for all children that way we only need to set the actual
            // color once.
            div,
            pre,
            footer,
            header {
                &.win-accent {
                    background-color: @value;


                    .win-type-header,
                    .win-type-subheader,
                    .win-type-title,
                    .win-type-subtitle,
                    .win-type-jumbo,
                    .win-type-base,
                    .win-type-body,
                    .win-type-lead,
                    .win-type-caption,
                    .win-h1,
                    .win-h2,
                    .win-h3,
                    .win-h4,
                    .win-h5,
                    .win-h6,
                    .win-button,
                    //.win-dropdown,
                    //.win-textbox,
                    .win-link,
                    //.win-textarea,
                    [class*="win-icon-"] {
                        color: white;
                    }
                }
            }


            .win-type-header,
            .win-type-subheader,
            .win-type-title,
            .win-type-subtitle,
            .win-type-jumbo,
            .win-type-base,
            .win-type-body,
            .win-type-lead,
            .win-type-caption,
            .win-h1,
            .win-h2,
            .win-h3,
            .win-h4,
            .win-h5,
            .win-h6,
            .win-button,
            //.win-dropdown,
            //.win-textbox,
            .win-link,
            //.win-textarea,
            [class*="win-icon-"] {
                &.win-accent {
                    color: @value;
                }
            }
        }

        &-@{key} when (@type = "container") {
            background-color: @value;

            .win-type-header,
            .win-type-subheader,
            .win-type-title,
            .win-type-subtitle,
            .win-type-jumbo,
            .win-type-base,
            .win-type-body,
            .win-type-lead,
            .win-type-caption,
            .win-h1,
            .win-h2,
            .win-h3,
            .win-h4,
            .win-h5,
            .win-h6,
            .win-button,
            //.win-dropdown,
            //.win-textbox,
            .win-link,
            //.win-textarea,
            [class*="win-icon-"] {
                color: white;
            }
        }

        &-@{key} when (@type = "text") {
            color: @value;
        }
    }
    // start the loop
    .loop(length(@list));
}




// overrides for pivot control ( might want to be moved somewhere else )
.win-accent .win-pivot button.win-pivot-header {
    color: rgba(255, 255, 255, 0.6);
}

.win-accent .win-pivot button.win-pivot-header:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}

.win-accent .win-pivot button.win-pivot-header-selected.win-pivot-header {
    color: rgb(255, 255, 255);
}
